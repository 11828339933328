import classnames from 'classnames'

import BaseModal from './BaseModal'
import modalFactory from './modalFactory'

import './modals.scss'


const PopUpModal = props => <BaseModal {...props} className={classnames('pop-up-modal', props.className)} />

const PopUpModalLarge = props => <BaseModal {...props} className={classnames('pop-up-modal pop-up-modal-large', props.className)} />

const SlidingModal = props => <BaseModal {...props} className={classnames('sliding-modal', props.className)} />


export default {
  BaseModal,
  ButtonModal: modalFactory(BaseModal, 'ButtonModal', 'button-modal'),
  ButtonPopUpModal: modalFactory(PopUpModal, 'ButtonPopUpModal', 'button-pop-up-modal'),
  ButtonPopUpModalLarge: modalFactory(PopUpModalLarge, 'ButtonPopUpModalLarge', 'button-pop-up-modal-large'),
  ButtonSlidingModal: modalFactory(SlidingModal, 'ButtonSlidingModal', 'button-sliding-modal'),
  FeedbackModal: modalFactory(PopUpModal, 'FeedbackModal', 'feedback-modal'),
  FullHeightModalLarge: modalFactory(PopUpModalLarge, 'FullHeightModal', 'full-height-modal'),
  GuideModal: modalFactory(PopUpModalLarge, 'GuideModal', 'guide-modal'),
  LinkConfirmPopUpModal: modalFactory(PopUpModal, 'LinkPopUpModal', 'confirm-pop-up-modal'),
  LinkModal: modalFactory(BaseModal, 'LinkModal', 'link-modal'),
  LinkPopUpModal: modalFactory(PopUpModal, 'LinkPopUpModal', 'link-pop-up-modal'),
  LinkPopUpModalLarge: modalFactory(PopUpModalLarge, 'LinkPopUpModalLarge', 'link-pop-up-modal-large'),
  LinkPromptPopUpModal: modalFactory(PopUpModal, 'LinkPopUpModal', 'prompt-pop-up-modal'),
  LinkSlidingModal: modalFactory(SlidingModal, 'LinkSlidingModal', 'link-sliding-modal'),
  modalFactory,
  PopUpModal,
  PopUpModalLarge,
  SlidingModal,
  SuccessPopUpModal: modalFactory(PopUpModal, 'SuccessPopUpModal', 'success-pop-up-modal'),
  WarningPopUpModal: modalFactory(PopUpModal, 'WarningPopUpModal', 'warning-pop-up-modal'),
  WrappedPopUpModal: modalFactory(PopUpModal, 'WrappedPopUpModal', 'wrapped-pop-up-modal'),
  WrappedPopUpModalLarge: modalFactory(PopUpModalLarge, 'WrappedPopUpModalLarge', 'wrapped-pop-up-modal-large')
}
