import {createTheme} from '@mui/material/styles'

import breakpoints from '../dashboard/styles/abstracts/breakpoints.module.scss'
import colors from '../dashboard/styles/abstracts/colors.module.scss'

const theme = createTheme({
  breakpoints: {
    values: {
      mobileSm: parseInt(breakpoints.mobileSm.replace('px', ''), 10),
      mobileMd: parseInt(breakpoints.mobileMd.replace('px', ''), 10),
      mobileLg: parseInt(breakpoints.mobileLg.replace('px', ''), 10),
      tabletSm: parseInt(breakpoints.tabletSm.replace('px', ''), 10),
      tabletMd: parseInt(breakpoints.tabletMd.replace('px', ''), 10),
      tabletLg: parseInt(breakpoints.tabletLg.replace('px', ''), 10),
      desktopSm: parseInt(breakpoints.desktopSm.replace('px', ''), 10),
      desktopMd: parseInt(breakpoints.desktopMd.replace('px', ''), 10),
      desktopLg: parseInt(breakpoints.desktopLg.replace('px', ''), 10),
      desktopXl: parseInt(breakpoints.desktopXl.replace('px', ''), 10)
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
          boxShadow: 'none',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: colors.everplansPrimaryLight,
            boxShadow: 'none'
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.tooltipBackground,
          borderRadius: '8px',
          fontSize: '10px',
          fontWeight: '400',
          lineHeight: '140%',
        },
        arrow: {
          color: colors.tooltipBackground  
        }
      }
    }
  },
  palette: {
    primary: {
      boxBackground: '#FFF8EF',
      main: '#14158B',
      light: colors.everplansPrimaryLight,
      contrastText: colors.buttonTextLight,
      text: '#262626'
    },
    secondary: {
      main: '#FF7670',
      green: colors.everplansSecondaryGreen
    }
  },
  shape: {
    borderRadius: '24px'
  },
  typography: {
    fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif'
  }
})

export default theme
